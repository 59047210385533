import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const Team = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'our team',
    paragraph: 'emotive is driven by a passionate team of student developers'
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={tilesClasses}>
            <div className='tiles-item reveal-from-bottom'>
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      style={{ borderRadius: '100%' }}
                      src={require('./../../assets/images/josiah.png')}
                      alt='Features tile icon 01'
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-0'>Josiah McCracken</h4>
                  <h6 className='mt-0 mb-8'>Project Lead</h6>
                  <p className='m-0 text-sm'>
                    Josiah is a senior computer science major with a passion for
                    blending the worlds of computers, design, and human
                    experience.
                  </p>
                </div>
              </div>
            </div>

            <div
              className='tiles-item reveal-from-bottom'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      style={{ borderRadius: '100%' }}
                      src={require('./../../assets/images/Micah.jpeg')}
                      alt='Features tile icon 02'
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-0'>Micah Johnson</h4>
                  <h6 className='mt-0 mb-8'>Backend Guru</h6>
                  <p className='m-0 text-sm'>
                    Micah is not only a computer scientist but a mathematician
                    to boot. he loves solving complex problems and investing in
                    other people.
                  </p>
                </div>
              </div>
            </div>

            <div
              className='tiles-item reveal-from-bottom'
              data-reveal-delay='400'
            >
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      style={{ borderRadius: '100%' }}
                      src={require('./../../assets/images/Wesley.jpeg')}
                      alt='Features tile icon 03'
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-0'>Wesley Cheah</h4>
                  <h6 className='mt-0 mb-8'>Frontend Wizard</h6>
                  <p className='m-0 text-sm'>
                    Wesley can not only code well, but also designs user
                    experiences that are a joy to use. he is currently in his
                    fourth year of studying computer science.
                  </p>
                </div>
              </div>
            </div>

            <div className='tiles-item reveal-from-bottom'>
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      style={{ borderRadius: '100%' }}
                      src={require('./../../assets/images/annika.png')}
                      alt='Features tile icon 04'
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-0'>Annika Kowalski</h4>
                  <h6 className='mt-0 mb-8'>Aesthetics Expert</h6>
                  <p className='m-0 text-sm'>
                    Annika is a junior computer science major with a knack for
                    design and putting herself in the user's shoes.
                  </p>
                </div>
              </div>
            </div>

            <div
              className='tiles-item reveal-from-bottom'
              data-reveal-delay='200'
            >
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image
                      style={{ borderRadius: '100%' }}
                      src={require('./../../assets/images/Kyle.jpeg')}
                      alt='Features tile icon 05'
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-0'>Kyle Luce</h4>
                  <h6 className='mt-0 mb-8'>Data Security Ninja</h6>
                  <p className='m-0 text-sm'>
                    Kyle's critical eye for details has been invaluable to the
                    emotive team. he is a cyber security major with an excellent
                    handle on public relations and backend development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
