import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Image from '../elements/Image';
import { withGetScreen } from 'react-getscreen';
import { ReactComponent as Logo } from './../../assets/images/logo.svg';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className='container-sm'>
        <div
          className={innerClasses}
          style={{ paddingBottom: 0, overflow: 'hidden' }}
        >
          <div className='hero-content'>
            {props.isMobile() ? (
              <div
                className='mt-0 mb-0 reveal-from-bottom'
                data-reveal-delay='200'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <h1 style={{ marginBottom: 0, paddingBottom: 0 }}>
                  welcome to
                </h1>
                <Logo
                  style={{ margin: '20px', padding: '0' }}
                  width={300}
                  height={300}
                />
              </div>
            ) : (
              <div
                className='mt-0 mb-0 reveal-from-bottom'
                data-reveal-delay='200'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <h1>welcome to </h1>
                <Logo
                  style={{ margin: '20px', padding: '0' }}
                  width={300}
                  height={300}
                />
              </div>
            )}
            <div className='container-xs'>
              <p
                className='m-0 mb-32 reveal-from-bottom'
                data-reveal-delay='400'
              >
                self-care is something often overlooked in our world today.
                emotive provides a quick and easy way to start your self-care
                journey.
              </p>
              <div className='reveal-from-bottom' data-reveal-delay='600'>
                <ButtonGroup>
                  <button
                    style={{
                      backgroundColor: 'rgb(0,0,0,0)',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                    wideMobile
                    onClick={() => {
                      window.open('https://testflight.apple.com/join/9MCr7MpL');
                    }}
                  >
                    <Image
                      src={require('./../../assets/images/testflight.png')}
                      alt='TestFlight'
                      width={220}
                      height={75}
                    />
                  </button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className='hero-figure reveal-from-bottom illustration-element-01'
            data-reveal-value='20px'
            data-reveal-delay='800'
          >
            <Image
              className='has-shadow'
              src={require('./../../assets/images/screens.png')}
              alt='Hero'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default withGetScreen(Hero);
