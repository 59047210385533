import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses} style={{ borderRadius: '20px' }}>
          <div className='cta-slogan'>
            <h3 className='m-0'>downlaod emotive beta</h3>
          </div>
          <div className='cta-action'>
            <button
              style={{
                backgroundColor: 'rgb(0,0,0,0)',
                border: 'none',
                cursor: 'pointer'
              }}
              wideMobile
              onClick={() => {
                window.open('https://testflight.apple.com/join/9MCr7MpL');
              }}
            >
              <Image
                src={require('./../../assets/images/testflight.png')}
                alt='TestFlight'
                width={220}
                height={75}
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
