import React from 'react';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';

const FooterNav = ({ className, ...props }) => {
  const classes = classNames('footer-nav', className);

  return (
    <nav {...props} className={classes}>
      <ul className='list-reset'>
        <li>
          <HashLink to='/#team' smooth offset={-20} duration={500}>
            About us
          </HashLink>
        </li>
        <li>
          <a href='mailto:help@emotiv.app'>Support</a>
        </li>
        <li>
          <a href='/privacy'>Privacy Polocy</a>
        </li>
        <li>
          <a href='/tos'>ToS</a>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
