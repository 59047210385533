import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import Team from '../components/sections/Team';
import FeaturesSplit from '../components/sections/FeaturesSplit';
//import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = () => {
  return (
    <>
      <Hero className='illustration-section-01' id='hero' />
      <FeaturesSplit
        invertMobile
        topDivider
        imageFill
        className='illustration-section-02'
        id='features'
      />
      {/* <Testimonial topDivider /> */}
      <Team id='team' />
      <Cta split id='download' />
    </>
  );
};

export default Home;
