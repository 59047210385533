import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { withGetScreen } from 'react-getscreen';

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'emotive is a safe space',
    paragraph:
      'emotive is designed to help you take care of yourself as well as others. whether you use emotive as a bite sized journal, a goal tracker, or a way to keep in touch with loved ones, it is a space you can be vulnerable in without judgement.'
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={splitClasses}>
            <div className='split-item' style={{ maxWidth: '900px' }}>
              <div
                className='split-item-content center-content-mobile reveal-from-left'
                data-reveal-container='.split-item'
              >
                <div className='text-xxs text-color-primary fw-600 tt-u mb-8'>
                  express yourself
                </div>
                <h3 className='mt-0 mb-12'>log your emotions</h3>
                <p className='m-0'>
                  using an avatar you craft yourself, you can log how you are
                  feeling at any moment of your day. you can keep it private or
                  share it with your own group of tight-knit people so that they
                  know how to best support you in the moment.
                </p>
              </div>
              <div
                className={classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container='.split-item'
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Image
                  src={require('./../../assets/images/log.png')}
                  alt='Features split 01'
                  style={{ height: '500px', width: '252px' }}
                />
                {!props.isMobile() ? (
                  <Image
                    src={require('./../../assets/images/home.png')}
                    alt='Features split 01'
                    style={{ height: '500px', width: '252px' }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className='split-item'>
              <div
                className='split-item-content center-content-mobile reveal-from-right'
                data-reveal-container='.split-item'
              >
                <div className='text-xxs text-color-primary fw-600 tt-u mb-8'>
                  stay motivated
                </div>
                <h3 className='mt-0 mb-12'>simplify your goals</h3>
                <p className='m-0'>
                  keep your goals simple and effective with emotive! we help you
                  create practical, timely, and measureable goals that will
                  minimize your time logging progress while keeping you
                  motivated!
                </p>
              </div>
              <div
                className={classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                style={{ display: 'flex', flexDirection: 'row' }}
                data-reveal-container='.split-item'
              >
                <Image
                  src={require('./../../assets/images/goals.png')}
                  alt='Features split 02'
                  style={{ height: '500px', width: '252px' }}
                />
                {!props.isMobile() ? (
                  <Image
                    src={require('./../../assets/images/calendar.png')}
                    alt='Features split 02'
                    style={{ height: '500px', width: '252px' }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className='split-item'>
              <div
                className='split-item-content center-content-mobile reveal-from-left'
                data-reveal-container='.split-item'
              >
                <div className='text-xxs text-color-primary fw-600 tt-u mb-8'>
                  stay in touch
                </div>
                <h3 className='mt-0 mb-12'>connect with your people</h3>
                <p className='m-0'>
                  its hard to grow alone! emotive will never be as good at
                  encouraging you as a friend would be, so we help provide
                  opportunities for you and your people to connect. encourage
                  each other's progress on goals, or get notified of a simple
                  way to help a friend on a bad day.
                </p>
              </div>
              <div
                className={classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container='.split-item'
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Image
                  src={require('./../../assets/images/people.png')}
                  alt='Features split 03'
                  style={{ height: '500px', width: '252px' }}
                />
                {!props.isMobile() ? (
                  <Image
                    src={require('./../../assets/images/profile.png')}
                    alt='Features split 02'
                    style={{ height: '500px', width: '252px' }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default withGetScreen(FeaturesSplit);
